import { FILTERS_EQUAL, MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Cooker Capacity" } }, [_c("text-input", { key: "cookerNo", attrs: { "label": "Cooker ID", "rules": "required", "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("text-input", { key: "cooker", attrs: { "label": "Cooker", "rules": "required", "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("switch-input", { key: "hydro", attrs: { "label": "Hydro", "parse": _vm.parseHydroValue, "parse-on-change": _vm.parseHydroValueOnChange, "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("select-input", { key: "canSizeId", attrs: { "advanced-filters": _vm.canSizesAdvancedFilters, "delete-filter": "IsInactive", "label": "Can Size", "lazy": "", "reference": "sterilization.common.can-sizes", "rules": "required", "source": "id", "source-label": "name", "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("number-input", { key: "capacity", attrs: { "label": "Cooker (Outer)", "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("number-input", { key: "pcCapacity", attrs: { "label": "Pressure Cooler", "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("number-input", { key: "acCapacity", attrs: { "label": "Atmospheric Cooler", "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("number-input", { key: "totalCapacity", attrs: { "label": "Total (Outer)", "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("number-input", { key: "capacityInner", attrs: { "label": "Cooker (Inner)", "span": 12, "disabled": !_vm.hasMaintainPermission } }), _c("number-input", { key: "totalCapacityInner", attrs: { "label": "Total (Inner)", "span": 12, "disabled": !_vm.hasMaintainPermission } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateCookerCapacityModal",
  inject: ["can", "crud"],
  data() {
    return {
      canSizesAdvancedFilters: [
        ["Facility", { operator: FILTERS_EQUAL, value: "PK,PP,PT" }]
      ],
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.can(this.permissions.maintain);
    }
  },
  created() {
    this.crud.updateEntity("createForm", { resourceKey: "hydro", value: 0 });
  },
  methods: {
    parseHydroValue(val) {
      return val ? true : false;
    },
    parseHydroValueOnChange(val) {
      return val ? -1 : 0;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateCookerCapacityModal = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-cooker-capacities" }, [_c("resource", { attrs: { "page": _vm.page, "name": "sterilization.cooker-capacities", "api-url": _vm.apiUrl, "create": _vm.CreateCookerCapacityModal, "redirect-route": "/sterilization-specifications/cooker-capacities" } }), _c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateCookerCapacity",
  data() {
    return {
      CreateCookerCapacityModal,
      page: STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
